import DataChart from '@/page/admin/finance/report/flightReport/component/data-chart.vue'
import PieChart from '@/page/admin/finance/report/flightReport/component/pie-chart.vue'
import moment from "moment";

import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
// 机票-消费合计
import consumer_flight_report_totalConsumption from '@/lib/data-service/haolv-default/consumer_flight_report_totalConsumption'
// 逐月消费
import consumer_flight_report_monthlyConsumption from '@/lib/data-service/haolv-default/consumer_flight_report_monthlyConsumption'
// 舱位分布
import consumer_flight_report_cabinPie from '@/lib/data-service/haolv-default/consumer_flight_report_cabinPie'
// 前五部门消费情况-金额
import consumer_flight_report_consumptionSituationAmount from '@/lib/data-service/haolv-default/consumer_flight_report_consumptionSituationAmount'
// 前五部门消费情况-张数
import consumer_flight_report_consumptionSituationNumber from '@/lib/data-service/haolv-default/consumer_flight_report_consumptionSituationNumber'
import echarts from "echarts";


export default {
    name: "FlightAllReport",
    data() {
        return {
            departmentList: [], // 部门列表
            params: {
                companyName: '',
                deptId: '',
                deptIdArr: [''],
                deptName: '',
                startTime: '',
                endTime: '',
                mold: '',
                type: '',
                dateArr: [],
                timeList: []
            },
            totalInfo: {}, // 机票消费合计
            options: [],
            monthType: '月度',
            monthlyConsumption: [], // 月度金额数据
            quarterConsumption: [], // 季度金额数据
            consumeData: {
                index: 0,
                datas: [],
                title: '金额（元）',
                list: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
            },
            fiveSectionData: {
                index: 0,
                datas: [],
                title: '金额（元）',
                list: []
            },
            fiveSectionNumber: {
                index: 0,
                datas: [],
                title: '数量（张）',
                list: []
            },
            spaceData: {
                index: 0,
                // legendData: ["公务舱", "头等舱", "经济舱",],
                seriesData: []
            }
        };
    },
    components: { DataChart, PieChart },
    methods: {
        // 获取部门列表
        getDepartmentList() {
            consumer_department_getDepartmentList().then(res => {
                let departmentList = res.datas.departmentListResults;
                departmentList.unshift({deptName: '全部', deptId: ''});
                this.departmentList = departmentList;
            })
        },
        changeDeptId(val) {
            console.log(val)
            let _this = this
            function getDeptName(list, activeId) {
                list.forEach(value => {
                    if (value.deptId === activeId) {
                        _this.params.deptName = value.deptName
                    }
                    if (value.childrenList && value.childrenList.length > 0) {
                        getDeptName(value.childrenList, activeId)
                    }
                })
            }
            if (val.length === 0) {
                this.params.deptId = ''
                this.params.deptName = ''
            } else {
                this.params.deptId = val[0]
                getDeptName(this.options, val[0])
            }
        },
        setTimeList() {
            if (this.params.timeList.length === 0) {
                let now = moment();
                let year = now.year();
                let month = now.month();
                let timeList = [];
                for (let i = 1, l = month + 2; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i;
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    };
                    timeList.push(item)
                }
                this.params.timeList = timeList
            }
        },
        disabledDate(time) {
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange;
            if (timeOptionRange) {
                // 如果有获取时间
                let year = moment(timeOptionRange).year();
                let timeYear = moment(time).year();
                return year !== timeYear
            } else {
                // 没有获取过时间，则全部都过
                return false
            }
        },
        onPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        changeOrderDate(val) {
            console.log(val);
            if (val === '' || val === null) {
                this.params.startTime = '';
                this.params.endTime = '';
                this.params.timeList = []
            } else {
                this.params.startTime = val[0];
                this.params.endTime = val[1];

                let year = this.params.startTime.split('-')[0];
                let startMonth = parseInt(this.params.startTime.split('-')[1]);
                let endMonth = parseInt(this.params.endTime.split('-')[1]);
                let timeList = [];
                for (let i = startMonth, l = endMonth + 1; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i;
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    };
                    timeList.push(item)
                }
                console.log(timeList);
                this.params.timeList = timeList
            }
        },
        search() {
            this.get_consumer_flight_report_totalConsumption();
            this.get_consumer_flight_report_monthlyConsumption();
            this.get_consumer_flight_report_cabinPie();
            this.get_consumer_flight_report_consumptionSituationAmount();
            this.get_consumer_flight_report_consumptionSituationNumber();
        },
        get_consumer_flight_report_totalConsumption() { // 消费合计
            consumer_flight_report_totalConsumption(this.params).then((res) => {
                this.totalInfo = res.datas
            })
        },
        changeMonthType(val) { // 修改为 月/季度
            this.consumeData.index = Math.ceil(Math.random() * 100); // 生成随机数赋值给key
            if (val === '月度') {
                this.consumeData.datas = this.monthlyConsumption; // 月度
                this.consumeData.list = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];

            } else {
                this.consumeData.datas = this.quarterConsumption; // 季度
                this.consumeData.list = ["第一季度", "第二季度", "第三季度", "第四季度"];
            }
        },
        get_consumer_flight_report_monthlyConsumption() { // 逐月消费
            consumer_flight_report_monthlyConsumption(this.params).then((res) => {
                this.consumeData.index = Math.ceil(Math.random() * 100); // 生成随机数赋值给key
                let priceArr = [];
                for(let item of res.datas.monthlyConsumptionList) { // 月度
                    priceArr.push(parseFloat(item.money));
                }
                this.monthlyConsumption = priceArr;
                for(let i = 0; i < priceArr.length; i += 3){ // 季度
                    this.quarterConsumption.push(eval(priceArr.slice(i, i+3).join('+')));
                }
                this.consumeData.datas = this.monthlyConsumption; // 月度
            });
        },
        get_consumer_flight_report_cabinPie() { // 舱位分布
            consumer_flight_report_cabinPie(this.params).then((res) => {
                this.spaceData.index = Math.ceil(Math.random() * 100); // 生成随机数赋值给key
                let newArr = []
                for (let key in res.datas) {
                    let val = {}
                    switch (key) {
                        case 'economy': // 经济舱
                            val = {
                                value: res.datas[key],
                                name: '经济舱'
                            };
                            break;
                        case 'first': // 头等舱
                            val = {
                                value: res.datas[key],
                                name: '头等舱'
                            };
                            break;
                        case 'business': // 公务舱
                            val = {
                                value: res.datas[key],
                                name: '公务舱'
                            };
                            break;
                    }
                    newArr.push(val);
                }
                this.spaceData.seriesData = newArr;
            });
        },
        get_consumer_flight_report_consumptionSituationAmount() { // 前五部门消费情况 (金额)
            consumer_flight_report_consumptionSituationAmount(this.params).then((res) => {
                this.fiveSectionData.index = Math.ceil(Math.random() * 100); // 生成随机数赋值给key
                let priceList = [],
                    depNameList = [];
                for (let item of res.datas.consumptionSituationAmountList) {
                    priceList.push(item.ordPrice)
                    depNameList.push(item.depName)
                }
                if (depNameList.length > 0) {
                    this.fiveSectionData.datas = priceList;
                    this.fiveSectionData.list = depNameList;
                }

            });
        },
        get_consumer_flight_report_consumptionSituationNumber() { // 前五部门消费情况 (数量)
            consumer_flight_report_consumptionSituationNumber(this.params).then((res) => {
                this.fiveSectionNumber.index = Math.ceil(Math.random() * 100); // 生成随机数赋值给key
                let numberList = [],
                    depNameList = [];
                for (let item of res.datas.consumptionSituationNumberList) {
                    numberList.push(item.number)
                    depNameList.push(item.depName)
                }
                if (depNameList.length > 0) {
                    this.fiveSectionNumber.datas = numberList;
                    this.fiveSectionNumber.list = depNameList;
                }
            });
        },

    },
    created() {
        this.getDepartmentList()
    },
    activated() {
        this.setTimeList();
        this.get_consumer_flight_report_totalConsumption();
        this.get_consumer_flight_report_monthlyConsumption();
        this.get_consumer_flight_report_cabinPie();
        this.get_consumer_flight_report_consumptionSituationAmount();
        this.get_consumer_flight_report_consumptionSituationNumber();
    },
    watch: {},
    computed: {}
};