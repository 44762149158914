// consumer_flight_report_monthlyConsumption 机票-逐月消费
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/flight-report-controller/monthlyConsumptionUsingPOST
const consumer_flight_report_monthlyConsumption = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/report/monthlyConsumption',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_report_monthlyConsumption;