//
//
//
//
//

import echarts from "echarts";

export default {
    name: "DataChart",
    data() {
        return {
            polar: {}
        };
    },
    props: {
        options: Object
    },
    methods: {},
    created() {
    },
    mounted() {
        this.polar = {
            grid: {
                top: 36,
                bottom: 59,
                right: 32
            },
            xAxis: {
                // type: 'category',
                data: this.options.list,
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {
                        color: "#D7D7D7"
                    }
                },
                axisLabel: {
                    interval: 0,
                    color: "#666"
                }
            },
            yAxis: {
                type: "value",
                name: this.options.title || "金额(元)",
                nameTextStyle: {
                    color: "#666"
                },
                axisLine: {
                    lineStyle: {
                        color: "#D7D7D7"
                    }
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                    interval: 0,
                    color: "#666"
                },
                splitLine: {
                    show: false
                }
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    // 坐标轴指示器，坐标轴触发有效
                    type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            series: [
                {
                    data: this.options.datas,
                    type: "bar",
                    barWidth: 10,
                    showBackground: false,
                    backgroundStyle: {
                        color: "rgba(220, 220, 220, 0.8)"
                    },
                    label: {
                        show: true,
                        color: "#666666",
                        formatter: "{c}",
                        position: "top"
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0, color: "#FF5A00"},
                            {offset: 0.5, color: "#FFE3D4"},
                            {offset: 1, color: "#FFFFFF"}
                        ])
                    }
                }
            ]
        };
    },
    activated() {
    },
    watch: {},
    computed: {}
};
