//
//
//
//
//

import echarts from "echarts";
export default {
  name: "PieChart",
  data() {
    return {
      polar: {}
    };
  },
  props: {
    options: Object
  },
  methods: {},
  created() {},
  mounted() {
    this.polar = {
      title: {
        left: "center"
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      color: [
        "rgba(255,111,33,1)",
        "rgba(255,111,33,0.8)",
        "rgba(255,111,33,0.62)",
        "rgba(255,111,33,0.5)",
        "rgba(255,111,33,0.3)",
        "rgba(255,111,33,0.2)",
        "rgba(255,111,33,0.1)"
      ],

      legend: {
        type: "scroll",
        orient: "vertical",
        right: 10,
        top: 20,
        bottom: 20,
        data: this.options.legendData
      },
      series: [
        {
          name: "舱位",
          type: "pie",
          radius: "55%",
          center: ["40%", "50%"],
          data: this.options.seriesData,
          label: {
            show: true,
            color: "#666666",
            formatter: "{b}{c}张",
            position: "top"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    };
  },
  activated() {},
  watch: {},
  computed: {}
};
